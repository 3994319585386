import { mapGetters } from 'vuex';
export default {
    data: function() {
      return {
        navs: [
          {
            id: '01',
            name: 'QRコード',
            path: '/shop/qr',
            routeName: 'ShopQr',
            includes: false,
          },
          {
            id: '02',
            name: 'QRコード登録',
            path: '/shop/qr/register',
            routeName: 'ShopQrRegister',
            includes: false,
          },
        ],
      };
    },
    computed: {
      ...mapGetters({
        useTicketFlag: 'event/useTicketFlag',
      }),
      pageNavs() {
        return this.navs.filter(e => {
          if (this.useTicketFlag) {
            return e.routeName !== 'ShopQrRegister';
          }
          return true;
        });
      }
    }
  };
  