<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    :storeModule="storeModule"
                    :dataTable="dataTable"
                    :labels="labels"
                  />
                  <Modal @close="closeModal" v-if="modal">
                    <template v-slot:body>
                      <qrcode-vue
                        class="qr"
                        :value="item.value || ''"
                        :size="240"
                        level="H"
                      />
                      <div class="qr-code">
                        <p class="qr-title w-242">{{ item.name }}</p>
                        <p class="qr-num">{{ item.value }}</p>
                      </div>
                    </template>
                    <template v-slot:footer>
                      <ul class="listGrid justify-content-end">
                        <li class="listGrid-item">
                          <button
                            class="btn btn-white"
                            type="button"
                            @click="closeModal"
                          >
                            閉じる
                          </button>
                        </li>
                      </ul>
                    </template>
                  </Modal>
                  <Modal @close="closeModal('cpmModal')" v-if="cpmModal">
                    <template v-slot:body>
                      <qrcode-vue
                        class="qr"
                        :value="item.cpmUrl || ''"
                        :size="240"
                        level="H"
                      />
                      <div class="qr-code">
                        <p class="qr-title w-242">{{ item.name }}</p>
                      </div>
                    </template>
                    <template v-slot:footer>
                      <ul class="listGrid justify-content-end">
                        <li class="listGrid-item">
                          <button
                            class="btn btn-white"
                            type="button"
                            @click="closeModal('cpmModal')"
                          >
                            閉じる
                          </button>
                        </li>
                      </ul>
                    </template>
                  </Modal>
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  v-if="$permission.isGroup()"
                  :modulePath="modulePath"
                  :subdomain="subdomain"
                  :listLength="qrCodeCount"
                ></PaginationLog>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section" v-if="!useTicketFlag">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    ref="table"
                    :checkedKey="checkedKey"
                    :storeModule="storeModule"
                    :dataTable="qrDataTable"
                    @select-items="handleSelectedItems"
                    :count="qrCount"
                    :labels="qrLabels"
                  />
                  <Modal @close="closeModal('qrModal')" v-if="qrModal">
                    <template v-slot:body>
                      <qrcode-vue
                        class="qr"
                        :value="item.code || ''"
                        :size="240"
                        level="H"
                      />
                      <div class="qr-code">
                        <p class="qr-title w-242 pre-line">{{ item.name }}</p>
                        <p class="qr-num">{{ item.code }}</p>
                      </div>
                    </template>
                    <template v-slot:footer>
                      <ul class="listGrid justify-content-end">
                        <li class="listGrid-item">
                          <button
                            class="btn btn-white"
                            type="button"
                            @click="closeModal('qrModal')"
                          >
                            閉じる
                          </button>
                        </li>
                      </ul>
                    </template>
                  </Modal>
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  ref="pagination"
                  :modulePath="qrModulePath"
                  :subdomain="subdomain"
                  :listLength="qrCount"
                  :limit="10"
                ></PaginationLog>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid" v-if="!useTicketFlag">
          <li class="listGrid-item">
            <button class="btn btn-red" :disabled="!isSelectedItemsExisted" type="button" @click="showDeleteModal">
              削除する
            </button>
          </li>
          <li class="listGrid-item pos-end">
            <router-link
              class="btn btn-main"
              :to="{ name: 'ShopQrRegister' }"
              >新規登録</router-link
            >
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <Modal @close="closeModal('deletedModal')" v-if="deletedModal">
      <template v-slot:headline>削除</template>
      <template v-slot:body>
        <p class="description text-align-center">
          対象のQRコードを削除しますか？
        </p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button
              class="btn btn-lg btn-white"
              type="button"
              @click="closeModal('deletedModal')"
            >
              キャンセル
            </button>
          </li>
          <li class="listGrid-item">
            <ActionButton
              class="btn btn-lg btn-red"
              :handle-submit="deleteQr"
              button-text="削除する"
            />
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import QrcodeVue from 'qrcode.vue';
import modal from '@/mixins/plugin/modal';
import Modal from '@/components/Modal.vue';
import shopQr from '@/mixins/nav/shopQr';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import { endline, formatLocalString } from '@/helpers/formatData';

export default {
  name: 'ShopQr',
  data: function() {
    return {
      pageName: 'QRコード',
      labels: [{ key: 'name', name: '店舗名' }, { isButtonColumn: true }],
      selectedItems: {
        allFlag: false,
        id: [],
      },
      checkedKey: 'id',
      storeModule: 'qrCode',
      modulePath: 'qrCode/getQrCodeList',
      qrModulePath: 'qrCode/getQrList',
      cpmModal: false,
      qrModal: false,
      deletedModal: false,
      endline,
      formatLocalString,
    };
  },
  components: {
    Modal,
    TableWithCheckbox,
    PaginationLog,
    QrcodeVue,
  },
  mixins: [modal, shopQr],
  computed: {
    ...mapGetters({
      lists: 'qrCode/qrCodeList',
      qrCodeCount: 'qrCode/qrCodeCount',
      qrCount: 'qrCode/qrCount',
      qrList: 'qrCode/qrList',
      userInfo: 'auth/infor',
      isUseCpmParentEvent: 'event/isUseCpmParentEvent',
      subdomain: 'auth/commonSubdomain',
      isGmoOrOfficeLoginStoreAndStore: 'common/isGmoOrOfficeLoginStoreAndStore',
      isGmoOrOfficeLoginStore: 'common/isGmoOrOfficeLoginStore',
      shopId: 'auth/commonShopId',
      useTicketFlag: 'event/useTicketFlag',
    }),
    qrLabels() {
      return [
        { isCheckbox: true },
        { key: 'shopName', name: '店舗名', tdClass: 'pre-line', notShow: !this.$permission.isGroup() },
        { key: 'name', name: '商品名・サービス名', tdClass: 'pre-line' },
        { key: 'amount', name: '金額' },
        { isButtonColumn: true }
      ];
    },
    dataTable() {
      return (
        (this.lists || []).map((item) => {
          return {
            ...item,
            name: item.name,
            buttonLinks: [
              {
                content: 'QRコードを表示',
                class: 'btn btn-sm btn-bd-main',
                function: () => this.showModal(null, item),
              },
              {
                isNotShown: !this.isUseCpmParentEvent,
                content: 'QRCPMカード払い',
                class: 'btn btn-sm btn-bd-main',
                function: () => this.showModal('cpmModal', item),
              },
            ]
          };
        })
      );
    },
    qrDataTable() {
      return (
        (this.qrList || []).map((item) => {
          return {
            ...item,
            shopName: endline(item?.shop?.name, 21),
            name: endline(item?.name, 25),
            amount: formatLocalString(item?.amount) + '円',
            buttonLinks: [
              {
                content: 'QRコードを表示する',
                class: 'btn btn-sm btn-bd-main',
                function: () => this.showModal('qrModal', item),
              },
              {
                content: '編集',
                class: 'btn btn-sm btn-bd-main',
                routeName: 'ShopQrEdit',
                params: { id: item.id },
              },
            ]
          };
        })
      );
    },
    isSelectedItemsExisted() {
      return this.selectedItems?.allFlag || this.selectedItems?.id.length > 0;
    },
  },
  methods: {
    handleSelectedItems(nVal) {
      this.selectedItems = {
        allFlag: nVal.allFlag,
        id: nVal.ids,
      };
    },
    showDeleteModal() {
      if (this.isSelectedItemsExisted) {
        this.showModal('deletedModal');
      } else {
        this.$message.noSelectedItem();
      }
    },
    async deleteQr() {
      const result = await this.$store.dispatch('qrCode/deleteQr', {
        subdomain: this.subdomain,
        data: this.selectedItems,
      });
      this.$refs.table.resetCheck();
      this.closeModal('deletedModal');
      if (result) {
        if (result.data.count) {
          this.$message.deleted('pointQR');
        } else {
          this.$message.showError('someoneUpdated');
        }
        this.$refs.pagination.resetPagination();
      }
    },
  },
  mounted() {
    const loading = this.$loading.show();
    Promise.all([
      this.isGmoOrOfficeLoginStoreAndStore ? this.$store.dispatch('qrCode/getQrCodeList', {
        subdomain: this.subdomain,
      }) : null,
      this.isGmoOrOfficeLoginStore ? this.$store.dispatch('qrCode/getQrList', {
        subdomain: this.subdomain,
        shopId: this.shopId
      }) : null,
    ]).finally(() => {
      this.$loading.clear(loading);
    })
  },
};
</script>
